<template>
  <v-col class="rmultipleselect pa-0">
    <v-row
      v-if="!alwaysOpen"
      class="selector-trigger ma-0"
      :class="{ disabled }"
      @click="onOpenPopup"
    >
      <v-col
        v-if="!selectedItems.length && !selectedItemsForChip.length"
        class="placeholder py-1 px-2"
      >
        {{ placeholder }}
      </v-col>
      <v-col
        v-else
        class="py-1 px-2 d-flex"
      >
        <r-chip
          v-for="item in labeledChipItems"
          :key="item.value"
          :label="item.label"
          :style="chipStyle"
        />
        <r-chip
          v-if="showMoreChipNum > 0"
          :label="`+ ${showMoreChipNum} more`"
          :style="chipStyle"
          outlined
        />
      </v-col>
      <v-col class="py-1 px-2">
        <v-row
          v-if="loading"
          class="ma-0"
          justify="end"
        >
          <v-progress-circular
            class="loading-icon"
            indeterminate
            color="#BDBDBD"
            width="2"
            size="20"
          />
        </v-row>
        <v-row
          v-else
          class="ma-0"
          justify="end"
        >
          <v-icon class="expand-icon mt-1">
            expand_more
          </v-icon>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="displayPopup"
      class="selector-popup ma-0"
    >
      <v-card
        class="selector-option-container elevation-0"
        :class="{ 'always-open': alwaysOpen}"
      >
        <v-col
          v-if="showSearch"
          class="pa-0 rmultipleselect--search-box-content"
          :class="{borderBottom: selectItems.length > 0}"
        >
          <r-search-input
            :placeholder="searchPlaceHolder"
            :value="query"
            @change="onInputChange"
          />
        </v-col>
        <slot name="prepend-item" />
        <slot name="filters" />
        <v-col
          ref="itemsWrapper"
          class="pa-0 selector-popup--items my-4"
          :style="{'max-height': hasCustomFiltersSlot ? '13rem' : '16rem'}"
        >
          <r-select-item
            v-for="prependItem in prependItems"
            :key="prependItem.value"
            class="rmultipleselect--prependitem"
            :item="prependItem"
            @selected="onClickPrependItem(prependItem)"
            @click:append-icon="onClickAppendIcon(prependItem)"
          />
          <r-select-item
            v-if="hasCustomFiltersSlot"
            :item="{}"
            class="rmultipleselect--filters"
          />
          <r-select-item
            v-if="showSelectAllOption"
            :key="SELECT_ALL.value"
            :item="SELECT_ALL"
            checkboxes
            class="rmultipleselect--item first--item"
            @deselected="onSelectAll(false)"
            @selected="onSelectAll(true)"
          />
          <v-col v-if="selectItems.length === 0">
            <no-listing-results />
          </v-col>
          <r-select-item
            v-for="item in selectItems"
            v-else
            :key="item.value"
            :disabled="disabled"
            checkboxes
            class="rmultipleselect--item"
            :item="item"
            @selected="onChangeItemSelection(item, true)"
            @deselected="onChangeItemSelection(item, false)"
          >
            <template
              v-if="hasCustomItemSlots"
              v-slot:item="slotProps"
            >
              <slot
                name="item"
                :item="slotProps.item"
              />
            </template>
          </r-select-item>
        </v-col>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
import didNotClickOn from '@/utils/didNotClickOn'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RSelectItem from '@/components/library/atoms/RSelectItem'
import RChip from '@/components/library/atoms/RChip'
import NoListingResults from '@/components/app/data/NoListingResults.vue'

const MAX_LABELLED_CHIPS = 3
const R_SELECT_METRICS = {
  OPTIMAL_SELECTOR_MARGIN: 2,
  SELECT_PADDING_RIGHT: 16,
  MAX_WIDTH_OF_MORE: 90,
  ARROW_DOWN_ICON_WIDTH: 15,
  SELECT_PADDING_LEFT: 16,
}
const SELECT_ALL = { label: 'Select All', value: 'all', selected: false }

export default {
  name: 'RMultipleSelect',
  components: {
    NoListingResults,
    RSearchInput,
    RSelectItem,
    RChip,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    searchPlaceHolder: {
      type: String,
      default: 'Search',
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    prependItems: Array,
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    totalRecords: {
      type: Number,
    },
    selectedRecordsCount: {
      type: Number,
      default: 0,
    },
    excludedRecordsCount: {
      type: Number,
      default: 0,
    },
    selectedItemsForChip: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showPopup: false,
      query: '',
      selectItems: [],
      SELECT_ALL,
      isAllSelected: false,
    }
  },
  computed: {
    labeledChipItems() {
      if (this.$props.selectedItemsForChip.length) {
        return this.$props.selectedItemsForChip.slice(0, MAX_LABELLED_CHIPS)
      }
      return this.selectedItems.slice(0, MAX_LABELLED_CHIPS)
    },
    showMoreChipNum() {
      if (this.isAllSelected) {
        return this.totalRecords - this.excludedRecordsCount - MAX_LABELLED_CHIPS
      }
      if (this.selectedRecordsCount) {
        return this.selectedRecordsCount - MAX_LABELLED_CHIPS
      }
      if (this.$props.selectedItemsForChip.length) {
        return this.selectedItemsForChip.length - MAX_LABELLED_CHIPS
      }
      return this.selectedItems.length - MAX_LABELLED_CHIPS
    },
    selectedItems() {
      return this.selectItems.filter((item) => item.selected)
    },
    hasCustomItemSlots() {
      return !!this.$scopedSlots.item
    },
    hasCustomFiltersSlot() {
      return !!this.$scopedSlots.filters
    },
    displayPopup() {
      const shouldShowPopup = this.showPopup || this.alwaysOpen
      this.$emit('on:popup-visibility-change', { shouldShowPopup })
      return shouldShowPopup
    },
    chipStyle() {
      if (this.$el) {
        const elementWidth = this.$el.getBoundingClientRect().width
        const {
          OPTIMAL_SELECTOR_MARGIN,
          ARROW_DOWN_ICON_WIDTH,
          MAX_WIDTH_OF_MORE,
          SELECT_PADDING_RIGHT,
          SELECT_PADDING_LEFT,
        } = R_SELECT_METRICS

        const maxWidth = Math.ceil((elementWidth
          - ARROW_DOWN_ICON_WIDTH
          - MAX_WIDTH_OF_MORE
          - SELECT_PADDING_LEFT
          - SELECT_PADDING_RIGHT) / MAX_LABELLED_CHIPS - 2 * OPTIMAL_SELECTOR_MARGIN)
        return {
          margin: `${OPTIMAL_SELECTOR_MARGIN}px`,
          'max-width': `${maxWidth}px`,
        }
      }
      return {}
    },
    showSelectAllOption() {
      return this.showSelectAll && !this.query && this.selectItems.length > 0
    },
  },
  watch: {
    items() {
      this.selectItems = [...this.items]
    },
    selectedRecordsCount(v) {
      this.SELECT_ALL = { ...SELECT_ALL, selected: v === this.totalRecords }
      this.isAllSelected = this.selectedRecordsCount === this.totalRecords
    },
  },
  mounted() {
    this.selectItems = [...this.items]
    this.SELECT_ALL = {
      ...SELECT_ALL,
      selected: this.selectedRecordsCount === this.totalRecords,
    }
    document.addEventListener('mousedown', this.onClickPage)
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.onClickPage)
  },
  methods: {
    onOpenPopup() {
      if (this.disabled) {
        return
      }
      this.showPopup = !this.showPopup
    },
    onClickPage(event) {
      if (didNotClickOn(event.target, { el: this.$el })) {
        this.showPopup = false
      }
    },
    onInputChange(eventData) {
      const { value } = eventData
      this.query = value.trim()
      this.$emit('change:search', { value })
    },
    onChangeItemSelection(selectedItem, selected) {
      this.selectItems = this.selectItems.map((item) => {
        if (item.value === selectedItem.value) {
          return {
            ...item,
            selected,
          }
        }
        return item
      })
      const selectedItems = this.selectItems.filter((item) => item.selected)
      if (this.showSelectAll) {
        if (!this.query) {
          this.SELECT_ALL = {
            ...this.SELECT_ALL,
            selected: selectedItems.length === this.totalRecords,
          }
        }

        this.$emit('on:singleAction', { selectedItem, selected })
      }

      if (selected) {
        this.$emit('change:selected', { value: selectedItem })
      } else {
        this.$emit('change:deselected', { value: selectedItem })
      }
      this.$emit('change', { value: selectedItems })
    },
    onSelectAll(isSelected) {
      this.selectItems = this.selectItems.map((item) => ({
        ...item,
        selected: isSelected,
      }))

      this.isAllSelected = isSelected
      this.$emit('on:select-all', { isSelectedAll: isSelected })
    },
    onClickPrependItem(item) {
      this.$emit('click:prepend-item', item)
    },
    onClickAppendIcon(item) {
      this.$emit('click:append-icon', item)
    },
  },
}
</script>

<style scoped>
.selector-trigger {
 cursor: pointer;
 border-radius: 3px;
}
.selector-trigger.disabled {
  background: var(--light-grey-color);
}
.selector-trigger {
  border: 1px solid var(--light-grey-color);
  user-select: none;
}
.selector-trigger:not(.disabled):hover,
.selector-trigger:not(.disabled).active {
  border-color: var(--primary-color)
}
.selector-popup {
  position: relative;
}
.selector-popup > .v-card {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  z-index: 1;
}
.selector-popup--items {
  overflow-y: auto;
}
.selector-option-container {
  max-height: 25rem;
  padding: 8px 16px;
}
.selector-option-container.always-open {
  border: none;
}

.rmultipleselect--search-box-content {
  padding-bottom: 16px !important;

}

.rmultipleselect--prependitem {
  border-bottom: 1px solid var(--r-border-color);
}

.rselectitem.rmultipleselect--item.checkboxes {
  margin-left: 5px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: #EEEEEE;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(63, 63, 63, 0.3);
}
.rmultipleselect--item.first--item {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #FFFFFF;
}
.borderBottom {
  border-bottom: 1px solid var(--r-border-color);
}
</style>
