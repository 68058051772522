var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "createEditClient"
  }, [_c('r-page-header', {
    staticClass: "createEditClient--header",
    attrs: {
      "previousroute": "/admin/clients",
      "label": _vm.isEdit ? "Edit ".concat(_vm.formData.name) : 'Create Client'
    }
  }), _c('r-error-message', {
    staticClass: "createEditClient--errormessage",
    attrs: {
      "errors": [_vm.updatingClientError, _vm.creatingClientError, _vm.fetchBecomeClientError, _vm.fetchLogoutAllUsersError, _vm.fetchClientError]
    },
    on: {
      "close": _vm.onCloseError
    }
  }), _c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('r-text-field', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.maxSymbol, _vm.rules.minSymbol],
      "value": _vm.formData.name,
      "label": "Name",
      "placeholder": "Client Name",
      "validate-on-blur": true,
      "hint": "'Name' will be replaced by the value from BCR when the client logs in to Reviews"
    },
    on: {
      "change": function change(value) {
        return _vm.onChange(value, 'name');
      }
    }
  }), _c('r-text-field', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.clientId],
      "value": _vm.formData.clientId,
      "type": "number",
      "label": "ClientID",
      "placeholder": "ClientID",
      "disabled": _vm.isEdit,
      "validate-on-blur": true
    },
    on: {
      "change": function change(value) {
        return _vm.onChange(value, 'clientId');
      }
    }
  }), _c('r-text-field', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value": _vm.formData.maxUrls,
      "label": "Max. URLs",
      "type": "number",
      "rules": [_vm.rules.min],
      "placeholder": "Max. URLs",
      "validate-on-blur": true
    },
    on: {
      "change": function change(value) {
        return _vm.onChange(value, 'maxUrls');
      }
    }
  }), _vm.isEdit ? _c('r-text-field', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "disabled": true,
      "value": _vm.formData.urlsUsed,
      "label": "Urls used",
      "type": "number"
    }
  }) : _vm._e(), _c('r-text-field', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value": _vm.formData.scrapeCadence,
      "label": "Scrape cadence",
      "type": "number",
      "placeholder": "Scrape Cadence",
      "rules": [_vm.rules.min, _vm.rules.max],
      "validate-on-blur": true
    },
    on: {
      "change": function change(value) {
        return _vm.onChange(value, 'scrapeCadence');
      }
    }
  }), _c('span', [_vm._v("Domains")]), _vm.totalDomainsWithoutFilter ? _c('r-select', {
    attrs: {
      "selected-records-count": _vm.selectedRecordsCount,
      "excluded-records-count": _vm.excludedDomainIds.length,
      "multiple": true,
      "total-records": _vm.totalDomainsWithoutFilter,
      "items": _vm.hostnameItems,
      "infinite": true,
      "show-select-all": true,
      "selected-items-for-chip": _vm.selectedDomains
    },
    on: {
      "on:get-more": _vm.onGetMoreItems,
      "change:search": _vm.onChangeSearch,
      "on:select-all": _vm.onSelectAllDomains,
      "on:deselect": _vm.onDeselect,
      "on:select": _vm.onSelect
    }
  }) : _vm._e(), _c('span', {
    staticClass: "d-block mt-3"
  }, [_vm._v("App features")]), _c('r-select', {
    attrs: {
      "multiple": "",
      "show-search": false,
      "items": _vm.appFeatureItems
    },
    on: {
      "change": function change(value) {
        return _vm.onChange(value, 'appFeatures');
      }
    }
  }), _c('div', {
    staticClass: "ml-n1"
  }, [_c('v-checkbox', {
    staticClass: "mt-3 pb-0 mb-0",
    attrs: {
      "color": "#239EDB",
      "dense": "",
      "label": "Suspended"
    },
    model: {
      value: _vm.formData.suspended,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "suspended", $$v);
      },
      expression: "formData.suspended"
    }
  }), _c('v-checkbox', {
    staticClass: "pt-0",
    attrs: {
      "color": "#239EDB",
      "dense": "",
      "label": "Enriched data"
    },
    model: {
      value: _vm.formData.enrichmentPipeline,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "enrichmentPipeline", $$v);
      },
      expression: "formData.enrichmentPipeline"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_vm.isEdit ? _c('v-col', [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "loading": _vm.fetchingBecomeClient,
      "label": "Become"
    },
    on: {
      "click": _vm.onClickBecomeClient
    }
  }), _c('r-button', {
    staticClass: "createEditClient--logout-all-btn",
    attrs: {
      "loading": _vm.fetchingLogoutAllUsers,
      "color": "red",
      "label": "Log out all users",
      "type": "button"
    },
    on: {
      "click": _vm.onClickLogoutAllUsers
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "d-flex align-center justify-end"
  }, [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "outlined": true,
      "label": _vm.$t('global.actions.cancel')
    },
    on: {
      "click": _vm.onClickCancel
    }
  }), _c('r-button', {
    attrs: {
      "label": _vm.isEdit ? 'Update' : 'Create'
    },
    on: {
      "click": _vm.onClickApply
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }