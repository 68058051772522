var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "rpageheader mx-0 pb-1",
    attrs: {
      "align": "center"
    }
  }, [_vm.previousroute ? _c('router-link', {
    staticClass: "mr-2",
    attrs: {
      "to": _vm.previousroute
    }
  }, [_c('v-icon', {
    attrs: {
      "large": "",
      "color": "black"
    }
  }, [_vm._v(" arrow_back ")])], 1) : _vm._e(), _c('span', {
    staticClass: "rpageheader--label text-truncate",
    class: {
      title: _vm.isTitle,
      'sub-title': _vm.isSubTitle
    },
    attrs: {
      "title": _vm.label
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _vm._t("postlabel"), _c('div', {
    staticClass: "end-slot-wrapper"
  }, [_vm._t("end")], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }