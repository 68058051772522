var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "rmultipleselect pa-0"
  }, [!_vm.alwaysOpen ? _c('v-row', {
    staticClass: "selector-trigger ma-0",
    class: {
      disabled: _vm.disabled
    },
    on: {
      "click": _vm.onOpenPopup
    }
  }, [!_vm.selectedItems.length && !_vm.selectedItemsForChip.length ? _c('v-col', {
    staticClass: "placeholder py-1 px-2"
  }, [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]) : _c('v-col', {
    staticClass: "py-1 px-2 d-flex"
  }, [_vm._l(_vm.labeledChipItems, function (item) {
    return _c('r-chip', {
      key: item.value,
      style: _vm.chipStyle,
      attrs: {
        "label": item.label
      }
    });
  }), _vm.showMoreChipNum > 0 ? _c('r-chip', {
    style: _vm.chipStyle,
    attrs: {
      "label": "+ ".concat(_vm.showMoreChipNum, " more"),
      "outlined": ""
    }
  }) : _vm._e()], 2), _c('v-col', {
    staticClass: "py-1 px-2"
  }, [_vm.loading ? _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-progress-circular', {
    staticClass: "loading-icon",
    attrs: {
      "indeterminate": "",
      "color": "#BDBDBD",
      "width": "2",
      "size": "20"
    }
  })], 1) : _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-icon', {
    staticClass: "expand-icon mt-1"
  }, [_vm._v(" expand_more ")])], 1)], 1)], 1) : _vm._e(), _vm.displayPopup ? _c('v-row', {
    staticClass: "selector-popup ma-0"
  }, [_c('v-card', {
    staticClass: "selector-option-container elevation-0",
    class: {
      'always-open': _vm.alwaysOpen
    }
  }, [_vm.showSearch ? _c('v-col', {
    staticClass: "pa-0 rmultipleselect--search-box-content",
    class: {
      borderBottom: _vm.selectItems.length > 0
    }
  }, [_c('r-search-input', {
    attrs: {
      "placeholder": _vm.searchPlaceHolder,
      "value": _vm.query
    },
    on: {
      "change": _vm.onInputChange
    }
  })], 1) : _vm._e(), _vm._t("prepend-item"), _vm._t("filters"), _c('v-col', {
    ref: "itemsWrapper",
    staticClass: "pa-0 selector-popup--items my-4",
    style: {
      'max-height': _vm.hasCustomFiltersSlot ? '13rem' : '16rem'
    }
  }, [_vm._l(_vm.prependItems, function (prependItem) {
    return _c('r-select-item', {
      key: prependItem.value,
      staticClass: "rmultipleselect--prependitem",
      attrs: {
        "item": prependItem
      },
      on: {
        "selected": function selected($event) {
          return _vm.onClickPrependItem(prependItem);
        },
        "click:append-icon": function clickAppendIcon($event) {
          return _vm.onClickAppendIcon(prependItem);
        }
      }
    });
  }), _vm.hasCustomFiltersSlot ? _c('r-select-item', {
    staticClass: "rmultipleselect--filters",
    attrs: {
      "item": {}
    }
  }) : _vm._e(), _vm.showSelectAllOption ? _c('r-select-item', {
    key: _vm.SELECT_ALL.value,
    staticClass: "rmultipleselect--item first--item",
    attrs: {
      "item": _vm.SELECT_ALL,
      "checkboxes": ""
    },
    on: {
      "deselected": function deselected($event) {
        return _vm.onSelectAll(false);
      },
      "selected": function selected($event) {
        return _vm.onSelectAll(true);
      }
    }
  }) : _vm._e(), _vm.selectItems.length === 0 ? _c('v-col', [_c('no-listing-results')], 1) : _vm._l(_vm.selectItems, function (item) {
    return _c('r-select-item', {
      key: item.value,
      staticClass: "rmultipleselect--item",
      attrs: {
        "disabled": _vm.disabled,
        "checkboxes": "",
        "item": item
      },
      on: {
        "selected": function selected($event) {
          return _vm.onChangeItemSelection(item, true);
        },
        "deselected": function deselected($event) {
          return _vm.onChangeItemSelection(item, false);
        }
      },
      scopedSlots: _vm._u([_vm.hasCustomItemSlots ? {
        key: "item",
        fn: function fn(slotProps) {
          return [_vm._t("item", null, {
            "item": slotProps.item
          })];
        }
      } : null], null, true)
    });
  })], 2)], 2)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }