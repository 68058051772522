var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._g({
    staticClass: "rbutton elevation-0",
    attrs: {
      "color": _vm.color,
      "loading": _vm.loading,
      "disabled": _vm.disabled,
      "outlined": _vm.outlined,
      "type": _vm.type,
      "fab": _vm.fab,
      "attrs": _vm.attrs,
      "small": _vm.small
    },
    on: {
      "click": _vm.onClick
    }
  }, _vm.on), [_vm.icon ? _c('v-icon', {
    attrs: {
      "data-testid": "icon",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]) : _vm._e(), _vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " ")];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }