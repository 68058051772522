<template>
  <div
    class="rselect"
  >
    <v-col
      v-if="multiple"
      class="pa-0"
    >
      <template>
        <slot name="label" />
      </template>
      <r-multiple-select
        ref="selectcomponent"
        :placeholder="placeholder"
        :search-place-holder="searchPlaceholder"
        :items="selectItems"
        :loading="loading"
        :disabled="disabled"
        :show-search="showSearch"
        :prepend-items="prependItems || []"
        :append-items="appendItems || []"
        :always-open="alwaysOpen"
        :selected-records-count="selectedRecordsCount"
        :excluded-records-count="excludedRecordsCount"
        :show-select-all="showSelectAll"
        :total-records="totalRecords"
        :selected-items-for-chip="selectedItemsForChip"
        @change="(data) => $emit('change', data)"
        @change:selected="(data) => $emit('change:selected', data)"
        @change:deselected="(data) => $emit('change:deselected', data)"
        @on:singleAction="({ selectedItem, selected }) =>
          onSingleItemChange({ selectedItem, selected })"
        @click:prepend-item="(data) => $emit('click:prepend-item', data)"
        @click:append-item="(data) => $emit('click:append-item', data)"
        @click:append-icon="(data) => $emit('click:append-icon', data)"
        @change:search="onSearchChange"
        @on:popup-visibility-change="toggleScrollEvents"
        @on:select-all="({ isSelectedAll }) => $emit('on:select-all', isSelectedAll)"
      >
        <template
          v-if="hasCustomFiltersSlot"
          v-slot:filters
        >
          <slot name="filters" />
        </template>
        <template
          v-if="hasCustomItemSlots"
          v-slot:item="slotProps"
        >
          <slot
            name="item"
            :item="slotProps.item"
          />
        </template>
        <template
          v-if="hasCustomPrependItemSlot"
          v-slot:prepend-item
        >
          <slot name="prepend-item" />
        </template>
      </r-multiple-select>
    </v-col>
    <v-col
      v-else
      class="pa-0"
    >
      <slot name="label" />
      <r-single-select
        ref="selectcomponent"
        :placeholder="placeholder"
        :search-placeholder="searchPlaceholder"
        :items="selectItems"
        :loading="loading"
        :disabled="disabled"
        :show-search="showSearch"
        :allow-empty="allowEmpty"
        :prepend-items="prependItems || []"
        :append-items="appendItems || []"
        @change="(data) => $emit('change', data)"
        @click:prepend-item="(data) => $emit('click:prepend-item', data)"
        @click:append-item="(data) => $emit('click:append-item', data)"
        @click:append-icon="(data) => $emit('click:append-icon', data)"
        @change:search="onSearchChange"
        @on:popup-visibility-change="toggleScrollEvents"
      >
        <template
          v-if="hasCustomPrependItemSlot"
          v-slot:prepend-item
        >
          <slot name="prepend-item" />
        </template>
      </r-single-select>
    </v-col>
  </div>
</template>

<script>
import RSingleSelect from '@/components/library/molecules/RSingleSelect'
import RMultipleSelect from '@/components/library/molecules/RMultipleSelect'
import debounce from '@/utils/debounce'
import isNumber from '@/utils/isNumber'

export default {
  name: 'RSelect',
  components: {
    RSingleSelect,
    RMultipleSelect,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    items: {
      type: Array,
      required: true,
    },
    prependItems: Array,
    appendItems: Array,
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    totalRecords: {
      type: Number,
    },
    selectedRecordsCount: {
      type: Number,
      default: 0,
    },
    excludedRecordsCount: {
      type: Number,
      default: 0,
    },
    selectedItemsForChip: {
      type: Array,
    },
  },
  data() {
    return {
      debounceGetMoreItems: [],
      selectItems: [],
      ticking: false,
    }
  },
  computed: {
    hasCustomItemSlots() {
      return !!this.$scopedSlots.item
    },
    hasCustomPrependItemSlot() {
      return !!this.$scopedSlots['prepend-item']
    },
    hasCustomFiltersSlot() {
      return !!this.$scopedSlots.filters
    },
  },
  watch: {
    items() {
      this.selectItems = [...this.items]
    },
  },
  mounted() {
    this.selectItems = [...this.items]
    this.validateProps()
    this.debounceGetMoreItems = debounce(this.onGetMoreItems, 100)
  },
  beforeDestroy() {
    if (this.infinite) {
      document.querySelectorAll('.selector-popup--items')
        .forEach((i) => i.removeEventListener('scroll', this.onScroll))
    }
  },
  methods: {
    onScroll(e) {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 1
            && (e.target.scrollHeight !== e.target.clientHeight)) {
            this.debounceGetMoreItems()
          }
          this.ticking = false
        })

        this.ticking = true
      }
    },

    onGetMoreItems() {
      this.$emit('on:get-more')
    },

    onSearchChange({ value }) {
      if (this.infinite) {
        this.$emit('change:search', { value })
      } else {
        const q = value.toLowerCase()
        this.selectItems = this.items.filter((item) => item.label.toLowerCase().includes(q))
        this.$emit('change:search', { value })
      }
    },
    async toggleScrollEvents({ shouldShowPopup }) {
      if (!this.infinite) {
        return
      }
      await this.$nextTick()
      if (shouldShowPopup) {
        const selectContainers = this.$refs.selectcomponent.$refs.itemsWrapper
        selectContainers.addEventListener('scroll', this.onScroll)
      } else {
        document.querySelectorAll('.selector-popup--items')
          .forEach((i) => i.removeEventListener('scroll', this.onScroll))
      }
    },
    validateProps() {
      if (this.$props.showSelectAll && !isNumber(this.$props.totalRecords)) {
        console.error('totalRecords is required')
      }
      if (this.$props.showSelectAll && !isNumber(this.$props.selectedRecordsCount)) {
        console.error('selectedRecordsCount is required')
      }
    },
    onSingleItemChange({ selectedItem, selected }) {
      if (selected) {
        return this.$emit('on:select', { selectedItem })
      }
      return this.$emit('on:deselect', { selectedItem })
    },
  },
}
</script>

<style scoped>
/deep/ .v-card {
  border-radius: 3px;
  border: 1px solid var(--r-border-color);
}
/deep/ .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/deep/ .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
/deep/ .selector-popup .selector-popup--items {
  max-height: 20rem;
  overflow-y: auto;
}
</style>
