var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return Object.keys(_vm.item).length ? _c('div', {
    staticClass: "rselectitem",
    class: {
      checkboxes: _vm.checkboxes,
      disabled: _vm.disabled
    },
    on: {
      "click": _vm.onClickRow
    }
  }, [_c('div', {
    ref: "selectItem",
    staticClass: "rselectitem--container"
  }, [_vm.hasCustomItemSlots || _vm.checkboxes ? _c('div', {
    staticClass: "d-flex align-center py-2",
    staticStyle: {
      "max-width": "calc(100% - 45px)"
    }
  }, [_vm.checkboxes ? _c('div', {
    staticClass: "rselectitem--checkbox"
  }, [_c('v-checkbox', {
    ref: "checkbox",
    attrs: {
      "disabled": _vm.disabled,
      "color": "#239EDB",
      "dense": ""
    },
    on: {
      "change": _vm.onChange
    },
    model: {
      value: _vm.isSelected,
      callback: function callback($$v) {
        _vm.isSelected = $$v;
      },
      expression: "isSelected"
    }
  })], 1) : _vm._e(), _vm.hasCustomItemSlots ? _c('div', {
    staticClass: "rselectitem--customitem text-truncate"
  }, [_vm._t("item", null, {
    "item": _vm.item
  })], 2) : _vm._e()]) : _vm._e(), !_vm.hasCustomItemSlots ? _c('div', {
    staticClass: "rselectitem--row py-2"
  }, [_c('span', {
    staticClass: "px-2 py-0 rselectitem--label"
  }, [_vm._v(" " + _vm._s(_vm.item.label) + " ")]), _vm.item.appendIcon ? _c('span', {
    staticClass: "px-4 py-0 rselectitem--appendicon"
  }, [_c('v-icon', {
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.item.appendIcon) + " ")])], 1) : _vm._e()]) : _vm._e()])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }